@layer modules, ui, base;
@layer ui {
  .sk-Avatar_main__vOWfc {
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-m);
  --sk-Avatar-size: var(--sk-data-display-avatar-size-m);

  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: var(--sk-Avatar-size);
  height: var(--sk-Avatar-size);
  overflow: hidden;
  text-align: center;
}

.sk-Avatar_sideImage__4ebVa {
  --sk-Avatar-side-image-size: var(--sk-data-display-avatar-side-image-size);
  --sk-Avatar-side-image-border-radius: var(--sk-data-display-avatar-side-image-radius);

  position: absolute;
  z-index: 1000;
  right: 0;
  bottom: 0;
  width: var(--sk-Avatar-side-image-size);
  height: var(--sk-Avatar-side-image-size);
  border-radius: var(--sk-Avatar-side-image-border-radius);
  background-color: var(--sk-color-white);
}

.sk-Avatar_sideImage__4ebVa > * {
  border-radius: var(--sk-Avatar-side-image-border-radius);
}

.sk-Avatar_main--fallback-icon__6hMe5 {
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
}

.sk-Avatar_main--squared__HPuTd > *:not(.sk-Avatar_sideImage__4ebVa) {
  border-radius: var(--sk-Avatar-border-radius);
}

.sk-Avatar_main--rounded__dp6R1 > *:not(.sk-Avatar_sideImage__4ebVa),
.sk-Avatar_main--rounded__dp6R1.sk-Avatar_main--fallback-icon__6hMe5 {
  border-radius: var(--sk-radius-rounded);
}

.sk-Avatar_main--size-m__4JSoT {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-m);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-m);
}

.sk-Avatar_main--size-l__6vabu {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-l);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-l);
}

.sk-Avatar_main--size-2xl__qhAOn {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-2xl);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-2xl);
}

.sk-Avatar_main--size-4xl__Hxi5Q {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-4xl);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-4xl);
}

.sk-Avatar_main--size-6xl___zsga {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-6xl);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-6xl);
}

}
@layer ui {
  .sk-Badge_main__AVFxz {
  --sk-Badge-y-padding: var(--sk-space-4);
  --sk-Badge-x-padding: var(--sk-space-8);
  --sk-Badge-label-spacing: var(--sk-space-4);
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-m-line-height));

  display: inline-flex;
  box-sizing: border-box;
  flex-grow: 0;
  align-items: center;
  align-self: end;
  justify-content: center;
  min-width: 0; /* Required for overflow: ellipsis; */
  height: var(--sk-Badge-height);
  padding: var(--sk-Badge-y-padding) calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  overflow: hidden;
  border-radius: var(--sk-radius-20);
  font-family: var(--sk-typography-data-display-badge-text-typography-m-font-family);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  gap: var(--sk-Badge-label-spacing);
}

.sk-Badge_label__f1crk {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.sk-Badge_icon__M8ilK {
  flex-shrink: 0;
}

.sk-Badge_main--withIcon__uFfRO {
  padding-right: calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  padding-left: var(--sk-Badge-x-padding);
}

.sk-Badge_main--medium__d1Pq_ {
  font-size: var(--sk-typography-data-display-badge-text-typography-m-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-m-line-height);
}

.sk-Badge_main--large__WUCcE {
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-l-line-height));

  font-size: var(--sk-typography-data-display-badge-text-typography-l-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-l-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-l-line-height);
}

.sk-Badge_main--tertiary__J_3Du {
  border-width: var(--sk-data-display-badge-size-border-tertiary);
  border-style: solid;
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-light);
  color: var(--sk-data-display-badge-color-primary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-light);
  color: var(--sk-data-display-badge-color-primary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-light);
  color: var(--sk-data-display-badge-color-secondary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-light);
  color: var(--sk-data-display-badge-color-secondary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-light);
  background-color: var(--sk-data-display-badge-color-background-tertiary-light);
  color: var(--sk-data-display-badge-color-tertiary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-light);
  color: var(--sk-data-display-badge-color-success-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-light);
  color: var(--sk-data-display-badge-color-success-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-light);
  color: var(--sk-data-display-badge-color-warning-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-light);
  color: var(--sk-data-display-badge-color-warning-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-light);
  color: var(--sk-data-display-badge-color-error-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-light);
  color: var(--sk-data-display-badge-color-error-faded-light);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-dark);
  color: var(--sk-data-display-badge-color-primary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-dark);
  color: var(--sk-data-display-badge-color-primary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-dark);
  color: var(--sk-data-display-badge-color-secondary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-dark);
  color: var(--sk-data-display-badge-color-secondary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-dark);
  background-color: var(--sk-data-display-badge-color-background-tertiary-dark);
  color: var(--sk-data-display-badge-color-tertiary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-dark);
  color: var(--sk-data-display-badge-color-success-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-dark);
  color: var(--sk-data-display-badge-color-success-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-dark);
  color: var(--sk-data-display-badge-color-warning-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-dark);
  color: var(--sk-data-display-badge-color-warning-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-dark);
  color: var(--sk-data-display-badge-color-error-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-dark);
  color: var(--sk-data-display-badge-color-error-faded-dark);
}

}
@layer ui {
  /* @define LayoutColumn */

.layouColumn_jds-LayoutColumn__QufQy {
  box-sizing: border-box;
  width: 100%;
  /*
  "min-width: 0" is used to prevent the carousel component to overflow and give a too big size at the layout column
  We cannod use a simple "overflow-x: hidden" on the layout cause we need to display the arrows which have a light overflow on the layout column
  */
  min-width: 0;
  margin: var(--spacer-100) var(--Layout-column-margin-size);
}

.layouColumn_jds-LayoutColumn__QufQy:first-child {
  margin-left: var(--Layout-column-margin-size);
}

.layouColumn_jds-LayoutColumn__QufQy:last-child {
  margin-right: var(--Layout-column-margin-size);
}

@media (min-width: 48rem) {
  .layouColumn_jds-LayoutColumn__QufQy {
    flex: 1;
    margin: 0;
  }

  .layouColumn_jds-LayoutColumn--fixedWidth__JCI_8 {
    --LayoutColumn-FixedWidth: calc(var(--Layout-column-size) * var(--layout-column-number) + ( var(--layout-column-number) - 1 ) * var(--Layout-column-gutter-size));
    flex: 0 0 var(--LayoutColumn-FixedWidth);
  }
}

}
@layer ui {
  /** @define Layout */

.layout_jds-Layout__hFF0F {
  --Layout-columnsBase: 12;
  --Layout-size: 100%;
  --Layout-padding: calc((100% - var(--Layout-size)) / 2);
  --Layout-column-gutter-size: var(--spacer-200);
  --Layout-column-margin-size: 1.25rem; /* 20px (specific spacer for Layout) */
  --Layout-column-size: calc(( ( var(--Layout-size) - var(--Layout-column-margin-size) * 2 ) - var(--Layout-column-gutter-size) * ( var(--Layout-columnsBase) - 1) ) / var(--Layout-columnsBase));

  display: flex;
  box-sizing: content-box; /* Fix box sizing to override some style coming from legacy component */
  flex-wrap: wrap;
  width: var(--Layout-size);
  padding: 0 var(--Layout-padding);
}

.layout_jds-Layout--isBo__hWLcI {
  --Layout-size: calc(100% - var(--jds-bo-width-delta, 0px));
}

.layout_jds-Layout--isFullWidth__Lkbb3 {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

.layout_jds-Layout--topPadding__vMye5 {
  padding-top: var(--spacer-400);
}

.layout_jds-Layout--bottomPadding__sa1oV {
  padding-bottom: var(--spacer-400);
}

.layout_jds-Layout--greyBlock__k6wDa {
  background: var(--color-grey--025);
}

.layout_jds-Layout--blackBlock__kQo1N {
  background: var(--color-black);
  color: var(--color-white);
}

.layout_jds-Layout--whiteBlock__KxtWG {
  background: var(--color-white);
}

.layout_jds-Layout--separator__6Jcn_ {
  border-bottom: 1px solid var(--color-grey--200);
}

@media (min-width: 48rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--medium-breakpoint);
    --Layout-column-gutter-size: var(--spacer-300);

    flex-wrap: initial;
    gap: var(--Layout-column-gutter-size);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--medium-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-400);
  }

  .layout_jds-Layout--topPadding__vMye5:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-top: var(--spacer-600);
  }

  .layout_jds-Layout--bottomPadding__sa1oV:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-bottom: var(--spacer-600);
  }
}

@media (min-width: 64rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--large-breakpoint);
    --Layout-column-gutter-size: var(--spacer-300);
    --Layout-column-margin-size: var(--spacer-300);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--large-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-400);
  }

  .layout_jds-Layout--topPadding__vMye5:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-top: var(--spacer-800);
  }

  .layout_jds-Layout--bottomPadding__sa1oV:not(.layout_jds-Layout--isBo__hWLcI) {
    padding-bottom: var(--spacer-800);
  }

  .layout_jds-Layout--isSmall__03qdy {
    --Layout-paddingIsSmall: calc(( var(--Layout-column-size) + var(--Layout-column-gutter-size) ) * 2);

    width: calc(var(--Layout-size) - var(--Layout-paddingIsSmall) * 2);
    padding-right: calc(var(--Layout-padding) + var(--Layout-paddingIsSmall));
    padding-left: calc(var(--Layout-padding) + var(--Layout-paddingIsSmall));
  }

  .layout_jds-Layout--isExtraSmall__Hf5k8 {
    --Layout-paddingIsExtraSmall: calc(( var(--Layout-column-size) + var(--Layout-column-gutter-size) ) * 3);

    width: calc(var(--Layout-size) - var(--Layout-paddingIsExtraSmall) * 2);
    padding-right: calc(var(--Layout-padding) + var(--Layout-paddingIsExtraSmall));
    padding-left: calc(var(--Layout-padding) + var(--Layout-paddingIsExtraSmall));
  }
}

@media (min-width: 80rem) {
  .layout_jds-Layout__hFF0F {
    --Layout-size: var(--major-breakpoint);
    --Layout-column-gutter-size: var(--spacer-400);
  }

  .layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--major-breakpoint) - var(--jds-bo-width-delta, 0px));
  }

  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-column-margin-size: var(--spacer-500);
  }
}

@media (min-width: 90rem) {
  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-size: var(--huge-breakpoint);
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .layout_jds-Layout--isFullWidth__Lkbb3.layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--huge-breakpoint) - var(--jds-bo-width-delta, 0px));
  }
}

@media (min-width: 120rem) {
  .layout_jds-Layout--isFullWidth__Lkbb3 {
    --Layout-size: var(--extra-breakpoint);

    max-width: var(--Layout-size);
    padding-right: var(--Layout-padding);
    padding-left: var(--Layout-padding);
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .layout_jds-Layout--isFullWidth__Lkbb3.layout_jds-Layout--isBo__hWLcI {
    --Layout-size: calc(var(--extra-breakpoint) - var(--jds-bo-width-delta, 0px));
  }
}

}
@layer ui {
  /*  @define CardTitle */
/* stylelint-disable plugin/selector-bem-pattern */
.cardTitle_jds-CardTitle__link__joX8c,
.cardTitle_jds-CardTitle__link__joX8c:visited,
.cardTitle_jds-CardTitle__link__joX8c:hover,
.cardTitle_jds-CardTitle__link__joX8c:active,
.cardTitle_jds-CardTitle__link__joX8c:focus,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:visited,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:hover,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:active,
[class*="jds-Link"].cardTitle_jds-CardTitle__link__joX8c:focus {
  outline: 0;
  text-decoration: none;
}

.cardTitle_jds-CardTitle__link__joX8c {
  font-weight: var(--bold);
}

.cardTitle_jds-CardTitle__link__joX8c::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: thin solid var(--color-grey--200);
  border-radius: var(--Card-radius);
  box-shadow: none;
}

.cardTitle_jds-CardTitle__link__joX8c:hover::after {
  border-color: var(--color-grey--300);
  box-shadow: var(--hover-shadow);
}

.cardTitle_jds-CardTitle__link__joX8c:active::after {
  border-color: var(--color-grey--400);
}

.cardTitle_jds-CardTitle__link__joX8c:focus::after {
  --CardBorder: 2px;
  border-color: var(--color-grey--200);
  outline: var(--CardBorder) solid var(--color-grey--400);
  outline-offset: var(--CardBorder);
}

@media (min-width: 64rem) {
  .cardTitle_jds-CardTitle--big__tFhdl {
    font-size: var(--heading);
    line-height: var(--headingLineHeight);
  }
}

}
@layer ui {
  /* @define CardImage */

.cardImage_jds-CardImage__I_GO2 {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

.cardImage_jds-CardImage__I_GO2::before {
  --CardImage-blurSize: 8px;

  content: "";
  position: absolute;
  z-index: 0;
  top: calc(var(--CardImage-blurSize) * -1);
  right: calc(var(--CardImage-blurSize) * -1);
  bottom: calc(var(--CardImage-blurSize) * -1);
  left: calc(var(--CardImage-blurSize) * -1);
  /* "--backgroundImageUrl" variable is defined at the html level (ex: <header style={{"--backgroundImageUrl": `url(url)`}}>) */
  background-image: var(--backgroundImageUrl);
  background-size: contain;
  filter: blur(var(--CardImage-blurSize)) brightness(75%);
}

.cardImage_jds-CardImage__image__jS8zd {
  position: absolute;
  top: 50%;
  width: 100%;
  height: auto;
  transform: translateY(-50%);
}

}
@layer ui {
  /* @define CardTagsListing */

.cardTagListing_jds-CardTagsListing__O6NOO {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none;
}

.cardTagListing_jds-CardTagsListing__item__WHxmt {
  display: inline-block;
  margin: 0 var(--spacer-100) var(--spacer-50) 0;
}

}
@layer ui {
  /* @define CardTime */

.cardTime_jds-CardTime__5StAm {
  display: block;
  margin-top: var(--spacer-200);
  color: var(--color-grey--700);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
[class*="jds-Card--isDark"] .cardTime_jds-CardTime__5StAm {
  color: var(--color-grey--200);
}

@media (min-width: 48rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 auto;
    align-self: flex-end;
    text-align: right;
  }
}

@media (min-width: 64rem) {
  .cardTime_jds-CardTime__5StAm:not(.cardTime_jds-CardTime--isPush__yMi7n) {
    flex: 1 0 auto;
  }
}

}
@layer ui {
  /* @define Listing */

.cardListing_jds-Listing__6rNno {
  display: block;
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.cardListing_jds-Listing__Item__nw0fv {
  display: block;
}

.cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--removeSpacing__fD5lO) .cardListing_jds-Listing__Item__nw0fv:not(:first-child) {
  margin-top: var(--spacer-200);
}

@media (min-width: 48rem) {
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) {
    display: block;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv {
    display: inline;
    margin-bottom: 0;
  }

  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv > * {
    vertical-align: middle;
  }

  /* stylelint-disable-next-line plugin/selector-bem-pattern */
  .cardListing_jds-Listing__6rNno:not(.cardListing_jds-Listing--isPush__XYDKK) .cardListing_jds-Listing__Item__nw0fv:not(:last-child)::after {
    content: "";
    display: inline-block;
    width: 1px;
    height: 12px;
    margin: 0 var(--spacer-100);
    background: var(--color-grey--500);
    vertical-align: middle;
  }
}

}
@layer ui {
  /* @define CardSpaceBetween */

.cardSpacebetween_jds-CardSpaceBetween__6e4V3 {
  align-items: flex-start;
}

.cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--removeSpacing__OQDQz) {
  margin-top: var(--spacer-200);
}

/* stylelint-disable-next-line plugin/selector-bem-pattern */
.cardSpacebetween_jds-CardSpaceBetween__6e4V3 > *:not(:first-child) {
  margin-top: var(--spacer-200);
}

.cardSpacebetween_jds-CardSpaceBetween--hasDivider__mapyk {
  padding-top: var(--spacer-200);
  border-top: var(--border);
}

@media (min-width: 48rem) {
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) {
    display: flex;
    justify-content: space-between;
    gap: var(--spacer-200);
  }

  /* stylelint-disable plugin/selector-bem-pattern */
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *,
  .cardSpacebetween_jds-CardSpaceBetween__6e4V3:not(.cardSpacebetween_jds-CardSpaceBetween--isPush__TJ16i) > *:not(:first-child) {
    margin-top: 0;
  }
  /* stylelint-enable plugin/selector-bem-pattern */
}

}
@layer ui {
  /* @define CardFeedback */

.cardFeedback_jds-CardFeedback__Lr8jw {
  margin-top: var(--spacer-200);
  color: var(--mainColor);
  font-weight: var(--bold);
}

.cardFeedback_jds-CardFeedback__Text__aoaF2 {
  margin-right: var(--spacer-100);
  color: var(--color-black);
  vertical-align: middle;
}

}
@layer ui {
  /**  @define FilterBar **/

.filterBar_jds-FilterBar___Fpze {
  display: flex;
  flex-direction: column;
  box-shadow: var(--default-shadow);
}

.filterBar_jds-FilterBar__element__dSN3u {
  flex: 1;
  min-width: 0;
  margin: 0;
  margin-right: -1px;
  margin-bottom: -1px;
}

.filterBar_jds-FilterBar__element__dSN3u:hover,
.filterBar_jds-FilterBar__element__dSN3u:focus-within {
  z-index: 1;
}

.filterBar_jds-FilterBar__element__dSN3u:not(:first-child, :last-child) .jds-Select__control {
  border-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
.filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (min-width: 48rem) {
  .filterBar_jds-FilterBar___Fpze {
    flex-direction: row;
  }

  .filterBar_jds-FilterBar__element__dSN3u:first-child .jds-Select__control,
  .filterBar_jds-FilterBar__element__dSN3u:first-child > div > input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: var(--radius-200);
  }

  .filterBar_jds-FilterBar__element__dSN3u:last-child .jds-Select__control {
    border-top-left-radius: 0;
    border-top-right-radius: var(--radius-200);
    border-bottom-left-radius: 0;
  }
}

}
@layer ui {
  /** @define Pagination */

.pagination_jds-Pagination__5KFrR {
  display: flex;
  justify-content: center;
  padding: 0;
  list-style-type: none;
}

.pagination_jds-Pagination__wrapper__wxN4Q {
  margin: var(--spacer-400) 0 var(--spacer-800) 0;
}

.pagination_jds-Pagination__item__55Rn3 {
  display: flex;
  align-items: center;
  margin-right: var(--spacer-200);
  text-align: center;
}

.pagination_jds-Pagination__item__55Rn3:last-child {
  margin-right: 0;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  background-color: transparent !important;
  color: var(--color-black) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw:hover {
  border-color: transparent;
  background-color: var(--color-grey--100) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART,
.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button__GgDAw {
  min-width: 40px;
  border-color: transparent !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__activeButton__djART {
  color: var(--themeButtonTextColor, var(--color-black)) !important;
}

.pagination_jds-Pagination__5KFrR .pagination_jds-Pagination__button--disabled__e4_45 {
  color: var(--color-grey--200) !important;
}

}
@layer ui {
  /* @define FormTemplate */

.Aside_jds-FormTemplate__aside__PtZzw {
  display: none;
}

.Aside_jds-FormTemplate__asideSticky__KjLEQ {
  position: sticky;
  top: var(--spacer-600);
}

/* stylelint-disable plugin/selector-bem-pattern */
.Aside_jds-FormTemplate__asideSticky__KjLEQ > *:first-child {
  margin-top: 0;
}
/* stylelint-enable plugin/selector-bem-pattern */

@media (min-width: 48rem) {
  .Aside_jds-FormTemplate__aside__PtZzw {
    display: flex;
    flex-direction: column;
  }
}

}
@layer ui {
  .InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k {
  position: relative;
  width: 100%;
  list-style-type: none;
  font-size: var(--smallBody);
  font-weight: var(--semiBold);
  line-height: var(--smallBodyLineHeight);
}

.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0.125rem; /* 2px */
  height: 100%;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k:focus-within:not(.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28)::before,
.InternalAnchorsItem_jds-InternalAnchorsItem__6d3_k:hover::before {
  background-color: var(--color-grey--500);
}

.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28:hover::before,
.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28::before {
  background-color: var(--color-black);
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD {
  display: block;
  padding: var(--spacer-150) var(--spacer-100) var(--spacer-150) var(--spacer-200);
  color: var(--color-grey--600);
  text-decoration: none;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:focus-visible {
  outline: none;
}

.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:hover,
.InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD:focus-visible {
  color: var(--color-grey--600);
}

.InternalAnchorsItem_jds-InternalAnchorsItem--active__r4e28 .InternalAnchorsItem_jds-InternalAnchorsItem__anchor__MxPeD {
  color: var(--color-black);
}

}
@layer ui {
  .InternalAnchors_jds-InternalAnchors__VuK70 {
  position: relative;
  height: -moz-fit-content;
  height: fit-content;
}

.InternalAnchors_jds-InternalAnchors__VuK70::before {
  content: "";
  position: absolute;
  left: 0;
  width: 0.125rem; /* 2px */
  height: 100%;
  background-color: var(--color-grey--100);
}

.InternalAnchors_jds-InternalAnchors__list__Dx6Ic {
  margin: 0;
  padding: 0;
}

}
@layer ui {
  /* @define FormTemplate */

/* stylelint-disable plugin/selector-bem-pattern */
.Body_jds-FormTemplate__body__ll3td > *:first-child {
  margin-top: 0;
}
/* stylelint-enable plugin/selector-bem-pattern */

}
@layer ui {
  /* @define FormTemplate */

.FormTemplate_jds-FormTemplate__plEnK {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.FormTemplate_jds-FormTemplate__body__3ROVp {
  flex-grow: 1;
  padding-top: var(--spacer-600);
  padding-bottom: var(--spacer-600);
  background-color: var(--color-grey--025);
}

.FormTemplate_jds-FormTemplate__body--without-aside__ibkpX {
  display: flex;
  justify-content: center;
}

/* stylelint-disable plugin/selector-bem-pattern */
.FormTemplate_jds-FormTemplate__body--without-aside__ibkpX > * {
  max-width: 800px;
}

}
@layer ui {
  /* @define FormTemplate */

.Footer_jds-FormTemplate__footer__o9mUC {
  --FormTemplate-Footer-z-index: 10;

  position: sticky;
  z-index: var(--FormTemplate-Footer-z-index);
  bottom: 0;
  border-top: solid 1px var(--color-grey--100);
  background-color: var(--color-white);
}

.Footer_jds-FormTemplate__footerWrapper__4MkIa {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: var(--spacer-200);
  padding-bottom: var(--spacer-200);
  gap: var(--spacer-200);
}

}
@layer ui {
  .FooterSeparator_jds-FormTemplate__footerSeparator__1RZYU {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 1px;
  height: 1.5rem;
  margin: var(--spacer-150) var(--spacer-100);
  border: 0;
  background-color: var(--color-grey--100);
}

}
@layer ui {
  /* @define FormTemplate */

.Header_jds-FormTemplate__header___x2Tm {
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem; /* 64px */
  padding-top: var(--spacer-200);
  padding-bottom: var(--spacer-200);
  gap: var(--spacer-100);
  overflow-x: auto;
}

@media (min-width: 48rem) {
  .Header_jds-FormTemplate__header___x2Tm {
    flex-wrap: nowrap;
  }
}

}
@layer ui {
  /* @define ResponsiveButtons */

.ResponsiveButtons_jds-ResponsiveButtons__hideMobile__ML96T {
  display: none;
}

.ResponsiveButtons_jds-ResponsiveButtons__fluidMobileButton__hNrPf {
  width: 100%;
}

@media (min-width: 48rem) {
  /* stylelint-disable-next-line plugin/selector-bem-pattern */ /* needed to ensure CSS priority */
  [class*="jds-Dropdown"].ResponsiveButtons_jds-ResponsiveButtons__mobileOnly__MWTvd {
    display: none;
  }

  .ResponsiveButtons_jds-ResponsiveButtons__hideMobile__ML96T {
    display: block;
  }

  .ResponsiveButtons_jds-ResponsiveButtons__fluidMobileButton__hNrPf {
    width: -moz-max-content;
    width: max-content;
  }
}

}
@layer ui {
  /** @define Pictogram */
.Pictogram_jds-Pictogram__ZG8GF {
  fill: currentcolor;
  vertical-align: middle;
}

.Pictogram_jds-Pictogram--minor__wQY3C {
  width: var(--picto-size-minor); /* 36px */
  height: var(--picto-size-minor); /* 36px */
}

.Pictogram_jds-Pictogram--small__0SrfD {
  width: var(--picto-size-small); /* 48px */
  height: var(--picto-size-small); /* 48px */
}

.Pictogram_jds-Pictogram--medium__z2GTT {
  width: var(--picto-size-medium); /* 60px */
  height: var(--picto-size-medium); /* 60px */
}

.Pictogram_jds-Pictogram--large__TFbj1 {
  width: var(--picto-size-large); /* 96px */
  height: var(--picto-size-large); /* 96px */
}

.Pictogram_jds-Pictogram--major__PoFzE {
  width: var(--picto-size-major); /* 112px */
  height: var(--picto-size-major); /* 112px */
}

}
@layer ui {
  .arrow_jds-arrow__GdEXX {
  display: none;
  z-index: 1 !important;
}

@media (min-width: 48rem) {
  .arrow_jds-arrow__GdEXX {
    /* Get the same display as the button with only icon */
    display: inline-flex;
    position: absolute;
    align-self: center;
    transform: translate(-50%, 0);
    border: none;
  }

  .arrow_jds-arrow__GdEXX:focus {
    outline: none !important;
  }

  .arrow_jds-arrow--hidden__tlIYW {
    display: none;
  }
}

}
@layer ui {
  .content_jds-content__separator__bOxyb {
  margin: 0 var(--spacer-100);
  background-color: var(--color-grey--100);
}

@media (min-width: 48rem) {
  .content_jds-content__wrapper__9e0A_ {
    position: relative;
    overflow-y: visible;
  }

  .content_jds-content__separator__bOxyb {
    margin: 0 var(--spacer-150);
  }
}

}
@layer ui {
  .header_jds-header--hidden__e3698 {
  opacity: 0;
}

}
@layer ui {
  .item_jds-item--hidden__QPhMf {
  opacity: 0;
}

}
@layer ui {
  /** @define Paper */

.paper_jds-Paper__W_bcw {
  padding: var(--spacer-300);
  background-color: var(--color-white);
  box-shadow: var(--default-shadow);
}

.paper_jds-Paper--isDark__BtGLj {
  background-color: var(--color-black);
  color: var(--color-white);
}

.paper_jds-Paper--noSpacing__5fBkQ {
  padding: 0;
}

.paper_jds-Paper--noShadow__6sUru {
  box-shadow: none;
}

.paper_jds-Paper--noBackground__9kbgQ {
  background-color: transparent;
}

.paper_jds-Paper--radius__qzXAo {
  border-radius: var(--radius-200);
}

.paper_jds-Paper--radiusRounded__GhgtS {
  border-radius: var(--radius-900);
}

.paper_jds-Paper--isFullWidth__pe_8P {
  padding-right: 0;
  padding-left: 0;
}

.paper_jds-Paper--hasBorder__DxYyT {
  border: var(--border);
}

@media (min-width: 48rem) {
  .paper_jds-Paper__W_bcw:not(.paper_jds-Paper--smallSpacing__GJYWV, .paper_jds-Paper--isFullWidth__pe_8P, .paper_jds-Paper--noSpacing__5fBkQ) {
    padding: var(--spacer-400) var(--spacer-600);
  }
}

}
@layer ui {
  /* @define FormTemplate */

.Section_jds-FormTemplate__section__fTP0Z {
  margin-top: var(--spacer-300);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04 {
  margin-top: var(--spacer-600);
}

.Section_jds-FormTemplate__section--withTitle__JaQ04:first-of-type {
  margin-top: 0;
}

.Section_jds-FormTemplate__paperSection___1G5q {
  /* Override default Paper component padding. */
  padding: var(--spacer-400) !important;
}

/* stylelint-disable plugin/selector-bem-pattern */
.Section_jds-FormTemplate__paperSection___1G5q > *:last-child {
  margin-bottom: 0;
}

/* stylelint-enable plugin/selector-bem-pattern */

}
@layer ui {
  /** @define Status */

.status_jds-Status__I8m97 {
  display: inline-flex;
  align-items: center;
}

.status_jds-Status__I8m97::before {
  content: "";
  display: inline-block;
  flex-shrink: 0;
  width: var(--icon-size-tiny);
  height: var(--icon-size-tiny);
  margin-right: var(--spacer-100);
  border-radius: var(--radius-900);
  vertical-align: middle;
}

.status_jds-Status--active__nZ2DW::before {
  background-color: var(--color-success--500);
}

.status_jds-Status--inactive__yIA4M::before {
  background-color: var(--color-error--500);
}

.status_jds-Status--warning__e_U_q::before {
  background-color: var(--color-warning--500);
}

.status_jds-Status--neutral__wkb7R::before {
  background-color: var(--color-grey--200);
}

}
@layer ui {
  /* @define Tag */

.Tag_jds-Tag__S_kl6 {
  --Tag-radius: var(--radius-200);
  /* Needed here to prevent calc() with 0 (without unit) which doesn't work. */
  /* stylelint-disable-next-line length-zero-no-unit */
  --Tag-removable-button-width: 0rem;
  /* medium by default */
  --Tag-horizontal-spacing: var(--spacer-150);
  --Tag-vertical-spacing: var(--spacer-50);

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  padding: var(--Tag-vertical-spacing) var(--Tag-horizontal-spacing);
  border-radius: var(--Tag-radius);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.Tag_jds-Tag--small__645vS {
  --Tag-horizontal-spacing: var(--spacer-100);
  --Tag-vertical-spacing: 0;

  font-size: var(--smallBody);
}

.Tag_jds-Tag--large__bg77j {
  --Tag-horizontal-spacing: var(--spacer-200);
  --Tag-vertical-spacing: var(--spacer-100);
}

.Tag_jds-Tag--medium__svQfh,
.Tag_jds-Tag--large__bg77j {
  font-size: var(--normalBody);
}

.Tag_jds-Tag--withIcon__zJH9U {
  padding-left: var(--spacer-100);
}

.Tag_jds-Tag--removable__KmanL[class*="small"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-small) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL[class*="medium"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-medium) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL[class*="large"] {
  /* padding-left + icon-width + padding-right */
  --Tag-removable-button-width: calc(var(--spacer-50) + var(--icon-size-big) + var(--spacer-150));
}

.Tag_jds-Tag--removable__KmanL {
  /* margin-left + Remove icon size */
  padding-right: calc(var(--spacer-50) + var(--Tag-removable-button-width));
}

.Tag_jds-Tag__Icon__XzZfg {
  margin-right: var(--spacer-100);
}

.Tag_jds-Tag__Label__WNaZc {
  /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Tag_jds-Tag__RemoveButton__yNPo2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: var(--Tag-removable-button-width);
  padding-right: var(--spacer-100);
  padding-left: var(--spacer-50);
  border: 0;
  border-top-right-radius: var(--Tag-radius);
  border-bottom-right-radius: var(--Tag-radius);
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.Tag_jds-Tag__RemoveButton__yNPo2:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.Tag_jds-Tag__RemoveButton__yNPo2:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.Tag_jds-Tag--cctheme__CP2rD .Tag_jds-Tag__RemoveButton__yNPo2 {
  color: var(--color-white);
}

/* @TODO: handle colors variant here */

.Tag_jds-Tag--default__4MLCo {
  background-color: var(--color-grey--100);
  color: var(--color-grey--700);
}

.Tag_jds-Tag--cctheme__CP2rD {
  background-color: var(--mainColor);
  color: var(--color-black);
}

.Tag_jds-Tag--purple__o_4tb {
  background-color: var(--color-purple--100);
  color: var(--color-purple--700);
}

.Tag_jds-Tag--warning__f1lkM {
  background-color: var(--color-warning--100);
  color: var(--color-warning--700);
}

.Tag_jds-Tag--pink__sY_QO {
  background-color: var(--color-pink--100);
  color: var(--color-pink--800);
}

.Tag_jds-Tag--cyan__ZpEK6 {
  background-color: var(--color-cyan--050);
  color: var(--color-cyan--800);
}

.Tag_jds-Tag--yellow__A7uJy {
  background-color: var(--color-yellow--050);
  color: var(--color-yellow--800);
}

.Tag_jds-Tag--dark__7gaeu {
  background-color: var(--color-grey--700);
  color: var(--color-white);
}

}
@layer ui {
  /** @define FieldContainer */

.fieldContainer_jds-FieldContainer__ZIUSU {
  display: block;
  position: relative;
  min-width: 0; /* reset default overflow content behavior of a fieldset */
  margin: 0 0 var(--spacer-200);
  padding: 0;
  border: none;
  color: var(--color-black);
  font-size: var(--normalBody);
}

/* stylelint-disable plugin/selector-bem-pattern */
.fieldContainer_jds-FieldContainer--disabled__s1R2l .fieldContainer_jds-Field__notRequiredText__gIF9c {
  color: inherit;
}
/* stylelint-enable */

.fieldContainer_jds-FieldContainer__switchText__Tbcki {
  padding-top: var(--spacer-50);
  font-weight: var(--normal);
}

}
@layer ui {
  /** @define Field */

.field_jds-Field__label__2NFTo {
  display: block;
  margin-bottom: var(--spacer-50);
  font-size: var(--body2);
  font-weight: var(--semiBold);
  line-height: var(--body2-lh);
}

.field_jds-Field__label--isResized__93_ei {
  transform: translateY(6px);
  font-size: var(--minorBody);
  line-height: var(--minorBodyLineHeight);
}

.field_jds-Field__label--disabled__zlX5R {
  color: var(--color-grey--200);
}

.field_jds-Field__notRequiredText__bfS7o {
  color: var(--color-grey--600);
  font-weight: var(--normal);
}

.field_jds-Field__notRequiredText__bfS7o::before {
  content: "-";
  margin: 0 var(--spacer-50);
}

.field_jds-Field__label--disabled__zlX5R .field_jds-Field__notRequiredText__bfS7o {
  color: var(--color-grey--200);
}

.field_jds-Field__inputContainer__OUGsz {
  position: relative;
  width: 100%;
  min-height: var(--field-height);
}

.field_jds-Field__iconWrapper__1YDn6 {
  position: absolute;
  top: 50%;
  right: var(--spacer-200);
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  transform: translateY(-50%);
  border: none;
  outline: none;
  background: transparent;
  color: var(--color-black);
}

.field_jds-Field__iconWrapper--left__vGTS1 {
  right: auto;
  left: var(--spacer-200);
}

.field_jds-Field__iconWrapper--disabled__WshVl {
  color: var(--color-grey--200);
}

.field_jds-Field__iconWrapper--cursor__86gc_:not(.field_jds-Field__iconWrapper--disabled__WshVl) {
  color: var(--color-grey--200);
  cursor: pointer;
}

.field_jds-Field__iconWrapper--cursor__86gc_:not(.field_jds-Field__iconWrapper--disabled__WshVl):hover {
  color: var(--color-black);
}

.field_jds-Field__icon__YwksS {
  display: block;
  width: 1.25rem;
  height: 1.25rem;
}

}
@layer ui {
  /** @define Input */

.input_jds-Input__x_yHZ {
  --Input-iconSize: 1.25rem;

  display: flex;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  min-height: var(--field-height);
  padding: 0 var(--spacer-200);
  border: thin solid var(--color-grey--200);
  border-radius: var(--radius-200);
  color: var(--color-black);
  font-family: inherit;
  font-size: var(--normalBody);
  font-weight: var(--normal);
  line-height: var(--normalBodyLineHeight);
}

.input_jds-Input--rightIcon__Hcje3 {
  padding-right: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input--leftIcon__BsHKd {
  padding-left: calc(var(--Input-iconSize) + var(--spacer-200) + var(--spacer-150));
}

.input_jds-Input__x_yHZ:disabled {
  border-color: var(--color-grey--200);
  background-color: var(--color-grey--025);
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ::-moz-placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ::placeholder {
  opacity: 1;
  color: var(--color-grey--500);
}

.input_jds-Input__x_yHZ:disabled::-moz-placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:disabled::placeholder {
  color: var(--color-grey--200);
}

.input_jds-Input__x_yHZ:not(:disabled):hover,
.input_jds-Input__x_yHZ:not(:disabled):focus {
  outline: 2px solid var(--color-grey--500);
  outline-offset: -1px;
}

.input_jds-Input--hasError__L_Tsh {
  border-color: var(--color-error--500);
}

.input_jds-Input--hasError__L_Tsh:not(:disabled):hover,
.input_jds-Input--hasError__L_Tsh:not(:disabled):focus {
  outline-color: var(--color-error--500);
}

}
@layer ui {
  /* @define Note */

.Note_jds-Note__SyY4K {
  display: flex;
  align-items: center;
  padding: var(--spacer-200) var(--spacer-200) var(--spacer-200) var(--spacer-300);
  gap: var(--spacer-200);
  border-radius: var(--radius-200);
}

.Note_jds-Note--loading__OPTYk {
  background-color: var(--color-grey--025) !important;
}

.Note_jds-Note--primary__tnFZB {
  background-color: var(--color-purple--100);
}

.Note_jds-Note--neutral__IOVI2 {
  background-color: var(--color-grey--050);
}

.Note_jds-Note--success__WSt9G {
  background-color: var(--color-success--100);
}

.Note_jds-Note--warning__hPqbt {
  background-color: var(--color-warning--100);
}

.Note_jds-Note--error__TXQcq {
  background-color: var(--color-error--100);
}

.Note_jds-Note__icon--success__v8HMq {
  color: var(--color-black);
}

.Note_jds-Note__icon--warning__8FwVO {
  color: var(--color-warning--500);
}

.Note_jds-Note__icon--error__Gx5Lw {
  color: var(--color-error--500);
}

.Note_jds-Note__body__TbCQO {
  flex: 1;
}

.Note_jds-Note__bodyTitle__PCf77 {
  font-weight: var(--semiBold);
}

.Note_jds-Note__closeButton__vLWj5 {
  align-self: flex-start;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

}
